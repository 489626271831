@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Krona+One&display=swap);
.root-app {
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  min-width: 320px;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

.floating-nav-button-container {
  position: fixed;
  bottom: 7.5vh;
  right: 20px;
  z-index: 3;
  overflow: hidden;
}

.floating-form {
  position: relative;
  margin: 0 auto;
  max-width: 350px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  overflow-y: auto;
}

.floating-form-back {
  text-align: left;
  background-image: linear-gradient(to right, #2e7d32, #4caf50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
}

.floating-contact-input-fields {
  width: 80%;
}

@media only screen and (max-width: 767px) {
  .floating-form {
    height: 80vh;
    width: 90vw;
  }
}

.info-bar-container {
  height: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(to right, #2e7d32, #4caf50);
}

.info-bar-tagline {
  height: 100%;
  margin-left: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.info-bar-about-container {
  flex: 1 1;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.info-bar-about {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-bar-p {
  margin-left: 10px;
  color: #ffffff;
}

@media only screen and (max-width: 1000px) {
  .info-bar-tagline {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .info-bar-container {
    display: none;
  }
}

.app-bar-container {
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.app-bar-spacer {
  display: none;
}

.app-bar-logo {
  flex: 4 1;
  height: 100%;
  margin-left: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.app-bar-links-container {
  flex: 5 1;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.app-bar-links {
  font-size: large;
  color: #4caf50;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none !important;
}

.app-bar-links:hover {
  background-color: #4caf50;
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .app-bar-container {
    position: fixed;
    background-image: linear-gradient(to right, #2e7d32, #4caf50);
    z-index: 3;
  }

  .app-bar-spacer {
    display: block;
    width: 100%;
    height: 75px;
  }

  .app-bar-logo {
    flex: 1 1;
    height: 75%;
  }

  .app-bar-links-container {
    padding: 0;
    margin-right: 20px;
    flex: 0 1;
    justify-content: center;
  }

  #app-bar-nav-top-container {
    text-align: center;
    background-image: linear-gradient(to right, #2e7d32, #4caf50);
    height: 75px;
    margin-bottom: 20px;
  }
}

.banner-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.banner-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.banner-image-slider {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.sd-pic {
  height: 100%;
  width: 100vw;
}

.banner-services {
  flex: 5 1;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
}

.banner-services-h1 {
  padding: 7.5% 0%;
  text-align: center;
  font-family: "Krona One", sans-serif;
  color: #00e676;
}

.banner-services-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  transform: translateX(10%);
}

.banner-services-list-item {
  flex: 50% 1;
  margin-bottom: 3%;
  font-size: large;
  font-weight: bolder;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.banner-services-list-item-text {
  padding-left: 15px;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.banner-services-list-item-text:hover {
  transform: scale(1.1);
}

.banner-form {
  height: 75%;
  flex: 2 1;
}

@media only screen and (max-width: 767px) {
  .banner-form {
    display: none;
  }

  .banner-services {
    background-color: rgba(0, 0, 0, 0.875);
  }

  .banner-services-list {
    transform: translateX(35%);
  }

  .banner-services-list-item {
    flex: 100% 1;
  }
}

@media only screen and (max-width: 600px) {
  .banner-services-list {
    transform: translateX(30%);
  }
}

@media only screen and (max-width: 550px) {
  .banner-services-list {
    transform: translateX(27.5%);
  }
}

@media only screen and (max-width: 470px) {
  .banner-services-list {
    transform: translateX(22.5%);
  }
}

.content-container {
  width: 100%;
  background-color: #f3f5fa;
}

.content-head-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.2% 2.5% 0% 2.5%;
}

.content-head-text {
  flex: 5 1;
  text-align: justify;
  padding: 0% 2.5%;
}

.content-head-video-container {
  flex: 4 1;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.content-head-video {
  width: 100%;
  height: 50vh;
  border: 0;
}

.content-body {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
}

.content-body-text {
  padding-right: 10px;
  text-align: justify;
}

.content-foot {
  width: 100%;
}

.content-foot-cards {
  display: flex;
  flex-direction: row;
  padding: 1% 2.5%;
}

.content-foot-card {
  height: 250px;
  flex: 1 1;
  margin: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2.5%;
}

.content-foot-accordions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2% 2.5%;
}

.content-foot-accordion {
  flex: 45% 1;
  margin: 10px;
}

.content-container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: x-large;
}

.content-container p {
  font-family: "Montserrat", sans-serif;
  font-size: larger;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .content-head-container {
    flex-wrap: wrap;
  }

  .content-head-text {
    flex: 100% 1;
  }

  .content-head-video-container {
    margin-top: 10px;
    padding: 10px;
    flex: 100% 1;
  }

  .content-foot-cards {
    flex-wrap: wrap;
    padding: 1% 10%;
  }

  .content-foot-card {
    height: 200px;
    flex: 100% 1;
    margin: 5px;
  }
}

.footer-container {
  width: 100%;
  padding: 2.5%;
  overflow: hidden;
}

.footer-about-card-social {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.footer-about-card-firm-text {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.footer-link-card > h3 {
  padding-top: 25px;
  flex: 1 1;
  color: #ffffff;
  font-weight: bold;
}

.footer-link-card > a {
  flex: 1 1;
  font-size: small;
  color: #9e9e9e;
}

.footer-contact-map-iframe {
  display: block;
  margin: 0 auto;
  height: 90%;
  width: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) {
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .footer-about-card {
    flex: 40% 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .footer-about-card-firm {
    flex: 5 1;
    width: 100%;
    overflow: hidden;
    padding: 5%;
  }

  .footer-about-card-firm-image {
    width: 35%;
  }

  .footer-about-card-social {
    flex: 3 1;
    padding: 0% 10%;
  }

  .footer-contact-map {
    flex: 35% 1;
  }

  .footer-link-card {
    flex: 25% 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about-card {
    text-align: center;
    padding: 5% 0%;
  }

  .footer-about-card-firm {
    padding: 2% 20%;
    overflow: hidden;
  }

  .footer-about-card-firm-image {
    height: 75px;
  }

  .footer-about-card-social {
    padding: 5% 20% 0% 20%;
  }

  .footer-contact-map {
    height: 300px;
  }

  .footer-link-card {
    padding: 1% 0% 0% 0%;
    width: 100%;
    text-align: center;
  }
}

.dev-banner {
  display: block;
  width: 100%;
  background-color: #000000;
  position: absolute;
  text-align: center;
}

.dev-banner-slogan {
  color: #616161;
}

.services-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.services-bar-wrapper {
  flex: 4 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.service-details {
  flex: 7 1;
  text-align: center;
  padding-right: 5vw;
}

.services-bar {
  width: 90%;
  padding-left: 3.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: linear-gradient(to right, #0062e6, #33aeff);
}

.services-bar-titles {
  font-size: large;
  padding: 10px;
  color: #ffffff;
  font-weight: bolder;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.services-bar-titles:hover {
  transform: scale(1.1);
}

.service-details h1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  color: #0062e6;
}

.service-details p,
li {
  text-align: justify;
  font-family: Montserrat, sans-serif;
  font-size: larger;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .services-container {
    flex-wrap: wrap;
  }

  .services-bar-wrapper {
    flex: 100% 1;
    margin-top: 20px;
  }

  .service-details {
    flex: 100% 1;
    padding: 20px;
    padding-right: 7.5vw;
  }
}

.team-card-temp-heading {
  font-size: 30px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  margin: 150px 20px;
  padding-bottom: 10vh;
  color: #0091ea;
}

.team-container {
  padding-left: 2vw;
  padding-right: 3.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-card {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
}

.team-card-image {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.team-card-details {
  flex: 2 1;
}

.team-card-details-heading {
  font-size: 30px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  margin: 0;
  color: #0091ea;
}

.team-card-details-title {
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
  color: #9e9e9e;
  font-weight: bold;
}

.team-card-details-para {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .team-container {
    padding-left: 5vw;
  }

  .team-card {
    flex-direction: column;
    justify-content: center;
  }

  .team-card-details {
    margin-top: 20px;
    text-align: center;
  }
}

.contact-container {
  height: 50vh;
  width: 100%;
  padding: 20px 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.contact-from {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.contact-info-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-map {
  flex: 1 1;
  height: 250px;
}

.contact-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding-right: 5px;
}

.contact-map-iframe {
  height: 100%;
  width: 100%;
}

.contact-info > p {
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  font-weight: bold;
}

.contact-input-fields {
  width: 80vw;
  max-width: 500px;
  flex: 1 1;
}

@media only screen and (max-width: 767px) {
  .contact-container {
    height: auto;
    flex-wrap: wrap;
  }

  .contact-info-container {
    flex: 100% 1;
  }

  .contact-map {
    margin-top: 20px;
    flex: 100% 1;
  }

  .contact-info {
    margin: 0 auto;
  }

  .contact-info > p {
    font-size: small;
  }
}

.admin-container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.admin-login-wrapper {
  height: 30vw;
  width: 85vw;
  margin: 20px;
  padding: 25px 20px;
  min-height: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.admin-query {
  overflow: hidden;
  word-wrap: break-word;
  padding: 25px;
  margin: 25px;
  background-image: linear-gradient(to right, #ef5350, #e57373);
}

.admin-container > h1 {
  font-family: "Krona One", sans-serif;
  padding: 20px;
  width: 100%;
  text-align: center;
}

