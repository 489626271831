@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");

.admin-container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.admin-login-wrapper {
  height: 30vw;
  width: 85vw;
  margin: 20px;
  padding: 25px 20px;
  min-height: 350px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.admin-query {
  overflow: hidden;
  word-wrap: break-word;
  padding: 25px;
  margin: 25px;
  background-image: linear-gradient(to right, #ef5350, #e57373);
}

.admin-container > h1 {
  font-family: "Krona One", sans-serif;
  padding: 20px;
  width: 100%;
  text-align: center;
}
